@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Rosmatika";
  src: local("Rosmatika"),
    url("./fonts/Rosmatika/Rosmatika.ttf") format("truetype");
  font-weight: bold;
}

html,
body {
  margin: 0;
  padding: 0;
  background: #e3e3e3;
}

.font-Rosmatika {
  font-family: "Rosmatika";
}
